body {
    background-color: #fff8e1;
    margin: 0;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

input[type="number"] {
    -moz-appearance: textfield;
}

.carousel-control-prev, .carousel-control-next, .carousel-indicators {
    display: none !important;
}